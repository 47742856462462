import React from 'react';
import { Link } from '@remix-run/react';

import kevin from '~/assets/images/kevin.jpg';
import pierre from '~/assets/images/pierre.jpg';
import seb from '~/assets/images/sebastien.jpg';

const team = [
  {
    name: 'Kevin Wenger',
    image: kevin,
    role: 'Developer',
    link: 'https://www.linkedin.com/in/kevinwenger/'
  },
  {
    name: 'Pierre Georges',
    image: pierre,
    role: 'Designer',
    link: 'https://www.linkedin.com/in/pierregeorges/'
  },
  {
    name: 'Sébastien Graf',
    image: seb,
    role: 'Developer',
    link: 'https://www.linkedin.com/in/seb-graf/'
  }
];

const About = () => (
  <div>
    <h1 className="h-3xl md:h-4xl mb-6">About us</h1>
    <div className="p-base mb-16 space-y-5">
      <p>
        Respawwwn is a daily puzzle game inspired by Wordle, Heardle &
        Framed.wtf, challenging your gaming knowledge in unique ways.
      </p>
      <p>
        Each day features a new challenge - either a series of screenshots, a
        360° panorama to explore, or iconic gaming sounds. Your goal is to
        identify the game and sometimes even pinpoint specific locations within
        it.
      </p>
      <p>
        We curate games from various genres, eras, and platforms, mixing
        well-known titles with hidden gems.
      </p>
    </div>
    <h2 className="h-xl md:h-2xl mb-6">The team</h2>
    <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
      {team.map(({ name, image, role, link }) => (
        <Link
          to={link}
          key={name}
          className="group flex gap-4 rounded-lg bg-gray-400 p-4 backdrop-blur-2xl max-md:items-center md:flex-col md:gap-5"
        >
          <div className="size-14 overflow-hidden rounded-lg shadow-[0px_6px_0px_#00000033] after:absolute after:inset-0 after:shadow-[0px_-3px_0px_#00000040_inset,_0px_2px_0px_#FFFFFF40_inset] group-odd:-rotate-3 group-even:rotate-3 md:size-20">
            <img src={image} alt="Kevin" className="size-full" />
          </div>
          <div>
            <p className="h-base mb-0.5 md:mb-1.5">{name}</p>
            <p className="p-sm text-purple-200">{role}</p>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

export default About;
