import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { whoami } from '~/api/userApi';
import type { User } from '~/types/api';

interface AuthenticatedContext {
  user: User;
  isLoading: boolean;
  isAuthenticated: true;
  checkAuth: () => Promise<void>;
}

interface UnauthenticatedContext {
  user: null;
  isLoading: boolean;
  isAuthenticated: false;
  checkAuth: () => Promise<void>;
}

type AuthContextType = AuthenticatedContext | UnauthenticatedContext;

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = user !== null;

  const checkAuth = async () => {
    try {
      const userData = await whoami();
      setUser(userData);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const value = useMemo(
    () =>
      ({
        user,
        isLoading,
        isAuthenticated,
        checkAuth
      }) as AuthContextType,
    [user, isLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
