import React, { useEffect, useRef } from 'react';
import { AnimatePresence, motion } from 'motion/react';

import pattern from '~/assets/images/pattern.png';
import Button from '~/components/Button';
import { useSound } from '~/hooks/useSound';
import { cn } from '~/utils/cn';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: 'small' | 'large';
  disabled?: boolean;
  forced?: boolean;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  size = 'large',
  disabled = false,
  forced = false
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const playMaximize = useSound('/sounds/maximize_008.mp3');
  const playMinimize = useSound('/sounds/minimize_008.mp3');

  useEffect(() => {
    if (isOpen) {
      playMaximize();
    }
  }, [isOpen, playMaximize]);

  useEffect(() => {
    if (!isOpen || !modalRef.current) return undefined;

    const focusableElements = modalRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusable = focusableElements[0] as HTMLElement;
    const lastFocusable = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    const handleTab = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable.focus();
        }
      } else if (document.activeElement === lastFocusable) {
        e.preventDefault();
        firstFocusable.focus();
      }
    };

    document.addEventListener('keydown', handleTab);
    firstFocusable?.focus();

    return () => {
      document.removeEventListener('keydown', handleTab);
    };
  }, [isOpen]);

  const handleClose = () => {
    playMinimize();
    onClose();
  };

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && !forced) {
        handleClose();
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', onEscape);
    } else {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', onEscape);
    }

    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('keydown', onEscape);
    };
  }, [isOpen]);

  if (disabled) {
    return null;
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={modalRef}
          role="dialog"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={cn(
            'fixed inset-0 isolate z-50 flex overflow-y-auto sm:p-6',
            size === 'small' &&
              'bg-gray-500 items-end sm:items-center sm:justify-center',
            size === 'large' && 'bg-purple-800 sm:bg-gray-500 backdrop-blur-2xl'
          )}
          onClick={!forced ? handleClose : undefined}
        >
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{
              ease: 'easeOut',
              duration: 0.3
            }}
            className={cn(
              'relative flex flex-col [overflow:clip] sm:rounded-2xl',
              size === 'small' &&
                'sm:max-w-lg sm:w-full mx-auto h-fit w-fit px-3 py-6 sm:p-10 max-sm:w-full',
              size === 'large' && 'min-h-full w-full h-fit'
            )}
            onClick={e => e.stopPropagation()}
          >
            {!forced && (
              <Button
                onClick={handleClose}
                variant="purple"
                data-cy="close-modal"
                className={cn(
                  'right-4 z-20 ml-auto  sm:right-6',
                  size === 'small' && 'absolute sm:right-3 sm:top-3 top-4',
                  size === 'large' &&
                    'sticky sm:right-6 top-safe-offset-4 sm:top-safe-offset-6'
                )}
                size="small"
                icon="close"
              >
                Close modal
              </Button>
            )}
            <div
              className={cn(
                'relative z-10 mx-auto max-w-lg w-full',
                size === 'small' && 'max-sm:grow max-sm:flex',
                size === 'large' &&
                  'pb-10 pt-safe-offset-10 sm:pb-28 sm:pt-safe-offset-16 px-4 sm:px-6'
              )}
            >
              {children}
            </div>
            <div
              className="circular-gradient absolute inset-x-0 top-0 h-full"
              style={{ backgroundImage: `url(${pattern})` }}
            >
              <div className="circular-gradient absolute inset-0" />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
