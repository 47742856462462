import React from 'react';

import Calendar from '~/assets/icons/calendar.svg?react';
import Close from '~/assets/icons/close.svg?react';
import Cross from '~/assets/icons/cross.svg?react';
import Cursor from '~/assets/icons/cursor.svg?react';
import Gear from '~/assets/icons/gear.svg?react';
import HandStar from '~/assets/icons/hand-star.svg?react';
import Image from '~/assets/icons/image.svg?react';
import Map from '~/assets/icons/map.svg?react';
import Panorama from '~/assets/icons/panorama.svg?react';
import Pause from '~/assets/icons/pause.svg?react';
import Pin from '~/assets/icons/pin.svg?react';
import Play from '~/assets/icons/play.svg?react';
import Question from '~/assets/icons/question.svg?react';
import Skull from '~/assets/icons/skull.svg?react';
import Smiley from '~/assets/icons/smiley.svg?react';
import Sound from '~/assets/icons/sound.svg?react';
import Star from '~/assets/icons/star.svg?react';
import { cn } from '~/utils/cn';

export type SVGProps = React.SVGProps<SVGSVGElement>;

const icons = {
  calendar: Calendar,
  close: Close,
  cross: Cross,
  cursor: Cursor,
  gear: Gear,
  handStar: HandStar,
  image: Image,
  map: Map,
  panorama: Panorama,
  pause: Pause,
  pin: Pin,
  play: Play,
  question: Question,
  skull: Skull,
  smiley: Smiley,
  sound: Sound,
  star: Star
} as const;

export type IconName = keyof typeof icons;

export type IconProps = {
  name: IconName;
  size?: number;
  className?: string;
  bigShadow?: boolean;
} & SVGProps;

const Icon = ({ name, size = 6, ...props }: IconProps) => {
  const RequestedIcon = icons[name] ?? Image;
  const sizeClass = `size-${size}`;

  return (
    <RequestedIcon {...props} className={cn(sizeClass, props.className)} />
  );
};

export default Icon;
