import React, { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { formatTimeLeft } from '~/utils/formatTimeLeft';

const SWISS_TIMEZONE = 'Europe/Zurich';

export default function DailyRiddleTimer() {
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const [targetTime, setTargetTime] = useState<number>(0);

  useEffect(() => {
    const getNextMidnightInSwitzerland = () => {
      const now = new Date();

      const swissHour = parseInt(
        formatInTimeZone(now, SWISS_TIMEZONE, 'HH'),
        10
      );
      const swissMinute = parseInt(
        formatInTimeZone(now, SWISS_TIMEZONE, 'mm'),
        10
      );

      const needsTomorrow =
        swissHour > 0 || (swissHour === 0 && swissMinute > 0);

      const targetDate = needsTomorrow ? addDays(now, 1) : now;

      const targetDateStr = formatInTimeZone(
        targetDate,
        SWISS_TIMEZONE,
        "yyyy-MM-dd'T'00:00:00.000XXX"
      );

      return new Date(targetDateStr);
    };

    setTargetTime(getNextMidnightInSwitzerland().getTime());

    const updateTimeLeft = () => {
      const now = new Date().getTime();
      const diffMs = targetTime - now;

      // If we're past midnight or have negative time, recalculate target time
      if (diffMs <= 0) {
        const newTargetTime = getNextMidnightInSwitzerland().getTime();
        setTargetTime(newTargetTime);
        setTimeLeft(formatTimeLeft(newTargetTime - now));
      } else {
        setTimeLeft(formatTimeLeft(diffMs));
      }
    };

    updateTimeLeft();
    const timer = setInterval(updateTimeLeft, 1000);

    // Add visibility change listener to update time when tab becomes visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateTimeLeft();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(timer);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [targetTime]);

  return (
    <div
      data-cy="next-daily-riddle-timer"
      className="bottom-10 right-10 z-10 flex w-fit flex-col gap-3 rounded-lg bg-black/25 p-2 text-center backdrop-blur-[32px] md:absolute"
    >
      <span className="text-sm">
        New riddle in{' '}
        <span className="inline-block w-15 whitespace-nowrap text-left">
          {timeLeft}
        </span>
      </span>
    </div>
  );
}
