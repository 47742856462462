import React, { useEffect, useState } from 'react';

import { addSubscription } from '~/api/subscriptionApi';
import { CustomWindow } from '~/types';
import { urlBase64ToUint8Array } from '~/utils/urlBase64ToUint8Array';

interface UseWebPushReturn {
  isSubscribing: boolean;
  error: string | null;
  success: boolean;
  isSubscribed: boolean | null;
  requestPermissions: () => Promise<void>;
}

export const useWebPush = (): UseWebPushReturn => {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const isIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  const isPWA = () =>
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as Navigator & { standalone?: boolean }).standalone ||
    document.referrer.includes('android-app://');

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
          return;
        }

        const registration = await navigator.serviceWorker.getRegistration();
        if (!registration) {
          return;
        }

        const subscription = await registration.pushManager.getSubscription();
        setIsSubscribed(!!subscription);
      } catch (err) {
        console.error('Error checking push subscription:', err);
      }
    };

    checkSubscription();
  }, []);

  const requestPermissions = async () => {
    setIsSubscribing(true);
    setError(null);
    setSuccess(false);

    try {
      // Check if user is on iOS and not running as PWA
      if (isIOS() && !isPWA()) {
        throw new Error(
          'Install the app on your device first using the share button'
        );
      }

      // Check if browser supports Service Workers
      if (!('serviceWorker' in navigator)) {
        throw new Error("Service Worker isn't supported on this browser");
      }

      // Check if browser supports Push
      if (!('PushManager' in window)) {
        throw new Error("Push isn't supported on this browser");
      }

      // Request notification permission
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        throw new Error('Permission not granted for Notification');
      }

      // Get service worker registration
      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        throw new Error('Service Worker not registered.');
      }

      // Check if already subscribed
      const subscribed = await registration.pushManager.getSubscription();
      if (subscribed) {
        console.info('User is already subscribed.', subscribed);
      } else {
        setIsSubscribed(false);
      }

      // Subscribe to push notifications
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          (window as unknown as CustomWindow).VAPID_PUBLIC_KEY
        )
      });

      // Send subscription to server
      await addSubscription(subscription);
      setSuccess(true);
      setIsSubscribed(true);
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : 'Something went wrong with WebPush Subscription!';
      setError(errorMessage);
      console.error(errorMessage);
    } finally {
      setIsSubscribing(false);
    }
  };

  return {
    isSubscribing,
    error,
    success,
    isSubscribed,
    requestPermissions
  };
};
