import React, { useEffect } from 'react';

import { useSoundContext } from '~/contexts/SoundContext';
import { useSound } from '~/hooks/useSound';

import Icon from './Icon';
import Tooltip from './Tooltip';

const SoundToggleButton = () => {
  const { isSoundEnabled, toggleSound } = useSoundContext();
  const playClickSound = useSound('/sounds/click_003.mp3');

  const handleChange = () => {
    toggleSound();
  };

  useEffect(() => {
    if (isSoundEnabled) {
      setTimeout(() => {
        playClickSound();
      }, 100);
    }
  }, [isSoundEnabled]);

  return (
    <Tooltip position="left" text={isSoundEnabled ? 'Sound on' : 'Sound off'}>
      <label
        htmlFor="sound-toggle"
        className="relative inline-flex cursor-pointer items-center"
      >
        <span className="sr-only">
          {isSoundEnabled ? 'Sound on' : 'Sound off'}
        </span>
        <input
          data-cy="sound-toggle"
          id="sound-toggle"
          type="checkbox"
          checked={isSoundEnabled}
          onChange={handleChange}
          className="peer sr-only"
        />
        <div className="relative flex items-center rounded-lg outline-1 peer-focus-visible:outline">
          <Icon name="sound" size={7} />
          {!isSoundEnabled && (
            <span
              className="absolute bottom-0 left-1/2 top-1/2 h-0.5 w-full 
              -translate-x-1/2 -translate-y-1/2 -rotate-45 
              rounded-2xl bg-white outline outline-2 
              outline-black drop-shadow-bottom-1"
            />
          )}
        </div>
      </label>
    </Tooltip>
  );
};

export default SoundToggleButton;
