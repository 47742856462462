import React, { useState } from 'react';
import { isNil } from 'ramda';

import { cn } from '~/utils/cn';

interface TooltipProps {
  text: string | null;
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}

const Tooltip = ({
  text,
  children,
  position = 'top',
  className
}: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false);

  if (isNil(text)) return children;

  return (
    <div
      className={cn('relative inline-block', className)}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <div
        className={cn(
          'absolute z-50 w-max max-w-32 rounded-lg bg-gray-500 backdrop-blur-2xl px-2 py-1 text-center text-sm text-white shadow-lg max-md:hidden',
          'opacity-0 transition-all translate-y-1 duration-300 pointer-events-none',
          isVisible && 'opacity-100 translate-y-0 pointer-events-auto',
          position === 'top' && 'bottom-full left-1/2 -translate-x-1/2 mb-3',
          position === 'bottom' && 'top-full left-1/2 -translate-x-1/2 mt-3',
          position === 'left' && 'right-full top-1/2 -translate-y-1/2 mr-3',
          position === 'right' && 'left-full top-1/2 -translate-y-1/2 ml-3'
        )}
      >
        <span className="w-full">{text}</span>
      </div>
    </div>
  );
};

export default Tooltip;
