import React, { useEffect, useState } from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import { motion } from 'motion/react';

import background from '~/assets/images/bg-home.jpeg';
import transparentPattern from '~/assets/images/pattern-transparent.png';
import About from '~/components/About';
import DailyRiddleTimer from '~/components/DailyRiddleTimer';
import Icon from '~/components/Icon';
import Login from '~/components/Login';
import MainMenuLink from '~/components/MainMenuLink';
import Modal from '~/components/Modal';
import { useToast } from '~/components/Toast';
import { useAuth } from '~/contexts/AuthContext';
import { useEnv } from '~/hooks/useEnv';
import { useWebPush } from '~/hooks/useWebPush';
import { ENV } from '~/types';
import { cn } from '~/utils/cn';
import { envLoader } from '~/utils/env';

export const loader = envLoader;

export default function Index() {
  const env = useLoaderData<ENV>();
  useEnv(env);

  const { user, isAuthenticated, isLoading: isAuthenticating } = useAuth();
  const { showToast } = useToast();

  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const {
    isSubscribing,
    isSubscribed,
    error: errorWebPush,
    success: successWebPush,
    requestPermissions: requestPermissionsWebPush
  } = useWebPush();

  // Handle web push notifications status
  useEffect(() => {
    if (successWebPush) {
      showToast('Successfully subscribed to daily notifications', 'success');
    }
  }, [successWebPush, showToast]);

  useEffect(() => {
    if (errorWebPush) {
      showToast(errorWebPush, 'error');
    }
  }, [errorWebPush, showToast]);

  const getWebPushButtonText = () => {
    if (isSubscribing) return 'Subscribing...';
    if (isSubscribed) return 'Subscribed to daily reminder';
    return 'Add a daily reminder';
  };

  return (
    <div className="relative flex size-full flex-col px-4 pb-safe-offset-6 pt-safe-offset-36 md:px-10 md:pb-safe-offset-10">
      {/* Background */}
      <div className="absolute inset-0 z-0 size-full">
        <div
          className="absolute inset-0 z-10 size-full"
          style={{
            backgroundImage: `url(${transparentPattern})`
          }}
        />
        <div className="left-gradient absolute inset-0 z-10 size-full" />
        <motion.img
          initial={{ scale: 1.1 }}
          animate={{ scale: 1 }}
          transition={{
            duration: 3,
            ease: 'circOut'
          }}
          src={background}
          alt="background"
          className="size-full object-cover opacity-50"
          loading="eager"
        />
      </div>

      {/* Main links */}
      <div className="relative flex w-fit flex-col gap-3 uppercase">
        <MainMenuLink
          to="/daily"
          label="Guess of the day"
          icon="question"
          variant="green"
        />
        <MainMenuLink
          to="/past-30-days"
          label="Past 30 days"
          icon="calendar"
          variant="blue"
        />
        <MainMenuLink
          to="#"
          label="Survival mode"
          icon="skull"
          soon
          variant="red"
        />
      </div>

      {/* Secondary links */}
      <div className="relative mt-auto flex w-fit flex-col gap-2 max-md:mb-6">
        {!isAuthenticated && (
          <button
            type="button"
            onClick={() => {
              if (!isAuthenticated) {
                setIsLoginModalOpen(true);
              }
            }}
            className="h-base flex items-center gap-2"
          >
            <Icon name="smiley" size={6} className="mb-px" />
            <span className="font-medium text-white">
              {isAuthenticating && 'Logging in...'}
              {!isAuthenticating && 'Sign In'}
            </span>
          </button>
        )}
        {isAuthenticated && (
          <p className="h-base flex items-center gap-2">
            <Icon name="smiley" size={6} className="mb-px" />
            <span data-cy="auth-user" className="font-medium text-white">
              {user.username}
            </span>
          </p>
        )}
        <button
          type="button"
          onClick={() => setIsAboutModalOpen(true)}
          className="h-base flex items-center gap-2"
        >
          <Icon name="question" size={6} className="mb-px" />
          About us
        </button>

        {/* WebPush Request Permissions */}
        {/* @TODO: Service Worker isn't supported on this browser, disable or hide UI. */}
        <button
          type="button"
          data-cy="webpush-request-permissions"
          className={cn(
            'h-base flex items-center gap-2',
            isSubscribing || isSubscribed ? 'opacity-50' : ''
          )}
          onClick={requestPermissionsWebPush}
          disabled={isSubscribed || isSubscribing === null}
        >
          <Icon name="calendar" size={6} className="mb-px" />
          {getWebPushButtonText()}
        </button>

        <Link
          to="#"
          className="h-base pointer-events-none flex items-center gap-2 opacity-50"
        >
          <Icon name="handStar" size={6} className="mb-px" />
          Help us
        </Link>

        <Link to="/privacy-policy" className="h-base flex items-center gap-2">
          <Icon name="skull" size={6} className="mb-px" />
          Privacy policy
        </Link>
      </div>

      {/* Time left */}
      <DailyRiddleTimer />

      <Modal
        isOpen={isAboutModalOpen}
        onClose={() => setIsAboutModalOpen(false)}
      >
        <About />
      </Modal>
      <Modal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        size="small"
        disabled={isAuthenticated}
      >
        <Login />
      </Modal>
    </div>
  );
}
