import React, { useEffect } from 'react';
import { useLoaderData } from '@remix-run/react';

import Button from '~/components/Button';
import Icon from '~/components/Icon';
import { useAuth } from '~/contexts/AuthContext';
import { useEnv } from '~/hooks/useEnv';

import type { ENV } from '~/types';

export default function Login() {
  const env = useLoaderData<ENV>();
  useEnv(env);
  const { checkAuth } = useAuth();

  const handleLogin = (e: React.MouseEvent) => {
    e.preventDefault();
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const popup = window.open(
      env.GOOGLE_OAUTH_URL,
      'Login with Google',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    if (!popup) {
      window.location.href = env.GOOGLE_OAUTH_URL;
      return;
    }

    popup.focus();
  };

  useEffect(() => {
    const handleAuthMessage = async (event: MessageEvent) => {
      if (
        event.origin === window.location.origin &&
        event.data?.type === 'auth-success'
      ) {
        await checkAuth();
      }
    };

    window.addEventListener('message', handleAuthMessage);
    return () => window.removeEventListener('message', handleAuthMessage);
  }, [checkAuth]);

  return (
    <div className="flex grow flex-col justify-end">
      <h2 className="h-2xl sm:h-3xl mb-8">Sign in</h2>
      <div className="mb-10 flex">
        <Button className="w-full text-center" onClick={handleLogin}>
          <span>Sign in with Google</span>
        </Button>
      </div>
      <ul className="space-y-1.5 *:flex *:gap-2.5">
        <li>
          <Icon name="star" className="shrink-0 text-yellow-500" />
          <span className="p-base">Track your scores</span>
        </li>
        <li>
          <Icon name="star" className="shrink-0 text-yellow-500" />
          <span className="p-base">Compete on the leaderboard</span>
        </li>
        <li>
          <Icon name="star" className="shrink-0 text-yellow-500" />
          <span className="p-base">
            Keep your progress across multiple devices
          </span>
        </li>
      </ul>
    </div>
  );
}
