import { ulid } from 'ulid';

const ANONYMOUS_ID_KEY = 'anonymous_id';

function generateUlid(): string {
  return ulid();
}

export function getOrCreateAnonymousId(): string {
  let storedId = localStorage.getItem(ANONYMOUS_ID_KEY);

  if (!storedId) {
    storedId = generateUlid();
    localStorage.setItem(ANONYMOUS_ID_KEY, storedId);
  }

  return storedId;
}
