import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { AnimatePresence, motion } from 'motion/react';

type ToastType = 'success' | 'error';

interface ToastItem {
  id: number;
  message: string;
  type: ToastType;
}

interface ToastContextType {
  showToast: (message: string, type: ToastType) => void;
  clearToasts: () => void;
}

const ToastContext = createContext<ToastContextType | null>(null);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

const Toast = ({
  message,
  onRemove
}: {
  message: string;
  onRemove: () => void;
}) => {
  useEffect(() => {
    const timer = setTimeout(onRemove, 3000);
    return () => clearTimeout(timer);
  }, [onRemove]);

  return (
    <motion.div
      className="w-fit rounded-2xl bg-gray-500 p-6 px-5 pb-5 shadow-hint backdrop-blur-2xl"
      initial={{ x: '-110%' }}
      animate={{ x: 0 }}
      exit={{ x: '-110%', transition: { duration: 0.2 } }}
      transition={{ type: 'spring', bounce: 0.2 }}
    >
      <p data-cy="toast" className="h-base [filter:none] [text-shadow:none]">
        {message}
      </p>
    </motion.div>
  );
};

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const showToast = useCallback((message: string, type: ToastType) => {
    const id = Date.now();
    setToasts(prev => [...prev, { id, message, type }]);

    setTimeout(() => {
      setToasts(prev => prev.filter(t => t.id !== id));
    }, 3000);
  }, []);

  const clearToasts = useCallback(() => {
    setToasts([]);
  }, []);

  const value = useMemo(
    () => ({ showToast, clearToasts }),
    [showToast, clearToasts]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      <div className="pointer-events-none fixed left-4 z-50 flex flex-col-reverse items-end gap-3 bottom-safe-offset-4 md:bottom-6 md:left-6">
        <AnimatePresence mode="popLayout">
          {toasts.map(toast => (
            <Toast
              key={toast.id}
              message={toast.message}
              onRemove={() =>
                setToasts(prev => prev.filter(t => t.id !== toast.id))
              }
            />
          ))}
        </AnimatePresence>
      </div>
    </ToastContext.Provider>
  );
};
