import { getApiUrl } from '~/api/utils';
import { User } from '~/types';
import { getOrCreateAnonymousId } from '~/utils/anonymous';

/**
 * Get the current User.
 *
 * @throws Error on API request failure.
 */
export const whoami = async (): Promise<User> => {
  const apiUrl = getApiUrl();
  const response = await fetch(`${apiUrl}/me`, {
    headers: {
      'X-Anonymous-ID': getOrCreateAnonymousId()
    },
    // This ensures cookies are sent with the request.
    credentials: 'include'
  });
  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  return response.json();
};
